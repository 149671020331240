@media print {
  .noPrint{
    display: none;
  }
}

@media screen {
  .onlyPrint{
    display: none;
  }
}

/*!* For desktop: *!*/
/*.col-1 {width: 25%;}*/

/*.col-2 {width: 16.66%;}*/
/*.col-3 {width: 25%;}*/
/*.col-4 {width: 33.33%;}*/
/*.col-5 {width: 41.66%;}*/
/*.col-6 {width: 50%;}*/
/*.col-7 {width: 58.33%;}*/
/*.col-8 {width: 66.66%;}*/
/*.col-9 {width: 75%;}*/
/*.col-10 {width: 83.33%;}*/
/*.col-11 {width: 91.66%;}*/
/*.col-12 {width: 100%;}*/

/*@media only screen and (max-width: 1200px) {*/
/*  !* For mobile phones: *!*/
/*  [class*="col-"] {*/
/*    width: 100%;*/
/*  }*/
/*}*/

.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.paragraphPrint {
  display: none;
}

body {
  background-color: #171C2B;
  color: white;
  font-family: "Nunito", sans-serif;
}

.App {
  text-align: center;
  display: inherit;
}

a.social {
  margin: 0 1rem;
  transition: transform 250ms;
  display: inline-block;
}

a.social:hover {
  transform: translateY(-4px);
}

.App-header {
  background-color: #20273C;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: white;
}

.App-footer {
  background-color: #20273C;
  flex-direction: row;
  color: white;
  min-height: 100px;
}

.App-content
{
  background-color: #171C2B;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.MuiDataGrid-columnHeaders {
  font-family: "Nunito", sans-serif;
  font-size: 11px;
}

.MuiDataGrid-row {
  font-family: "Nunito", sans-serif;
  font-size: 11px;
}

.MuiDataGrid-row:hover {
  cursor: pointer;
}

.MuiDataGrid-row.Mui-even {
  color: white;
  background-color: #20273C;
  opacity: 1;
}

.MuiDataGrid-row.Mui-odd {
  color: white;
  background-color: #141428;
  opacity: 1;
}

.clickable:hover
{
  cursor: pointer;
}


/* HighChart Customizations */
.highcharts-background {
  fill: #20273C;
}

.breadCrumbDisplay
{
  color: #416AF9;
}

.breadCrumbLink
{
  color: black;
}

/* Large devices (laptops/desktops, 600px and up) */
@media only screen and (min-width: 600px) {
  .header-image {
    content:url("https://www.monecracy.com/images/logo.png");
    max-height: 50px;
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .header-image {
    max-height: 50px;
    max-width: 50px;
    content:url("https://www.monecracy.com/images/logo_icon.png");
  }
}
